import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const PasswordDialog = ({ open, onClose, onPasswordSubmit }) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    onPasswordSubmit(password);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: "20px" }}>
        <TextField
          label="Enter Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: "20px" }}>
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

export default PasswordDialog;
