import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import baseURL from "../../axiosConfig";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReplayIcon from '@mui/icons-material/Replay';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles } from '@mui/styles';

//axios.defaults.baseURL = 'https://elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;

const useStyles = makeStyles({
  disabledButton: {
    borderRadius: '32px',
    height: '40px',
    opacity: 0.6 /* Example: reduce opacity when disabled */

  },
});

const BallotVote = () => {
  const { ballot } = useParams();
  const [ballotStatistics, setBallotStatistics] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [voterData, setVoterData] = useState(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [lastVotedIds, setLastVotedIds] = useState([]);

  const [inputText, setInputText] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);

  const fixedDivRef = useRef(null);

  const mainBackgroundColor = "#f1f1f4";
  const bottomBackgroundColor= "#c1c1c3";
  const classes = useStyles();

  useEffect(() => {
    const fetchBallotStatistics = async () => {
      try {
        const response = await axios.get('/elections/statistics');
        const ballotNumber = parseInt(ballot, 10) / 10;
        const ballotNumberString = ballotNumber.toString();
        const ballotStats = response.data.ballot_number_voting_percentage.find(stats => stats.ballot_number === ballotNumberString);
        setBallotStatistics(ballotStats);
      } catch (error) {
        console.error('Error fetching ballot statistics:', error.message);
      }
    };
    fetchBallotStatistics();
  }, [ballot]);

  useEffect(() => {
    // Calculate and set the margin-bottom dynamically
    const fixedDivHeight = fixedDivRef.current.clientHeight;
    const voterDataDiv = document.getElementById("voterDataDiv");
    if (voterDataDiv) {
      voterDataDiv.style.marginBottom = `${fixedDivHeight+15}px`;
    }
  }, [voterData]);

  const addLastVotedId = (voterInfo) => {
    setLastVotedIds((prevIds) => {
      // Check if the ID already exists in the list
      const idExists = prevIds.some((info) => info.id === voterInfo.id);
  
      // If the ID doesn't exist, add it to the list
      if (!idExists) {
        const newIds = [...prevIds.slice(-5), voterInfo]; // Keep the last 5 voterInfos
        return newIds;
      }
  
      return prevIds;
    });
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const styles = {
    outlinedInput: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
        textAlign: "right"
      },
      '& .MuiInputBase-input': {
        color: 'black',
        textAlign: "right"
      },
      '& .MuiInputLabel-root': {
        color: 'black',
        textAlign: "right"
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '32px',
      },
    },
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setInputText(text);
  };

  useEffect(() => {
    clearTimeout(searchTimeout);
    const timeoutId = setTimeout(() => {
      if (inputText.trim() !== "") {
        axios
          .get(`/elections/data`, {
            params: {
              ballot_order_number: inputText,
              ballot: ballot,
              page: 1
            }
          })
          .then((response) => {
            const voterInfo = response.data.results[0];
            setVoterData(voterInfo);
          })
          .catch((error) => {
            console.error("Error fetching data:", error.message);
          });
      }
    }, 1000);

    setSearchTimeout(timeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputText, ballot]);

  const handleVote = async () => {

    
    try {
      // Check if the voter has already voted
    if (voterData && voterData.voted) {
      // Display a toast notification for already voted
      notifyAlreadyVoted();
    } else {
      // Update the data only if the voter has not already voted
      await axios.put(`/elections/data_update`, {
        ballot_order_id: inputText,
        ballot_number: ballot,
        voted: true,
      });
    

      if (!voterData) {
        notifyUserNotFound();
      } else {
        setBallotStatistics((prevStats) => ({
          ...prevStats,
          voted_count: prevStats.voted_count + 1,
        }));
        notifySuccess();
        addLastVotedId({
          id: inputText,
          first_name: voterData.first_name,
          last_name: voterData.last_name,
        });
      }
    }

      setSearchId("");
      setInputText("");
      setVoterData(null)
    } catch (error) {
      notifyUserNotFound();
      console.error('Error updating data:', error.message);
    }
  };

  const notifySuccess = () => toast("🐙🐙🐙 הצביע");
  const notifyUserNotFound = () => toast.error("❌ !!!מספר סידורי לא נמצא");
  const notifyAlreadyVoted = () => toast.warning("🚫 הבוחר כבר הצביע!");
  const notifyError = () => toast("תקלה טכנית... 🤷‍♂️");

  const handleRevert = async () => {
    const idToRevert = prompt("ביטול הצבעה למי?");
    if (idToRevert && !isNaN(idToRevert)) {
      try {
        await axios.put(`/elections/data`, {
          ballot_order_id: idToRevert,
          ballot_number: ballot,
          voted: false, // Revert the vote
        });

        // Update voted count in local state
        setBallotStatistics((prevStats) => ({
          ...prevStats,
          voted_count: prevStats.voted_count - 1,
        }));

        setLastVotedIds((prevIds) => prevIds.filter((id) => id !== idToRevert));

        notifySuccessRevert();
      } catch (error) {
        notifyUserNotFound();
        console.error('Error reverting vote:', error.message);
      }
    }
  };

  const handleRevertWithId = async (idToRevert) => {
    if (!isNaN(idToRevert)) {
      try {
        await axios.put(`/elections/data`, {
          ballot_order_id: idToRevert,
          ballot_number: ballot,
          voted: false, // Revert the vote
        });

        // Update voted count in local state
        setBallotStatistics((prevStats) => ({
          ...prevStats,
          voted_count: prevStats.voted_count - 1,
        }));

        setLastVotedIds((prevIds) =>
          prevIds.filter((voterInfo) => voterInfo.id !== idToRevert)
        );
        notifySuccessRevert();
      } catch (error) {
        notifyUserNotFound();
        console.error('Error reverting vote:', error.message);
      }
    }
  };


  const notifySuccessRevert = () => toast("🔄 הצבעה בוטלה!");



  const getBallotDisplay = (ballot) => {
    const ballotNumber = parseInt(ballot);
    if (!isNaN(ballotNumber) && ballotNumber % 10 === 0 && ballotNumber >= 10 && ballotNumber <= 90) {
      const displayNumber = ballotNumber / 10;
      return `קלפי ${displayNumber}`;
    }
    return "Unknown Ballot";
  };

  return (
    <div style={{
      backgroundColor: mainBackgroundColor, minHeight: "100vh",
      display: "flex", flexDirection: "column", padding: '0 10px 0 10px'
    }}>
      {/* Header displaying total voted count */}
      {ballotStatistics && (
        <Typography variant="h1" style={{ textAlign: "center", color: "black" }}>
          عدد الناخبين في الصندوق الحالي: {ballotStatistics.voted_count}
        </Typography>
      )}
      <div style={{ textAlign: 'right' }} >
        <h1 style={{ color: "black", textAlign: "center" }}>قائمه ي.ن برئاسه الحاج سليم</h1>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="100px"
          height="100px"
          src={`../../assets/elections-logo.jpg`}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <h1 style={{ color: "black", textAlign: "center" }}>{getBallotDisplay(ballot)}</h1>
  
      <TextField
        label="מס' סידורי"
        style={{ direction: "rtl", borderRadius: '32px' }}
        variant="outlined"
        value={inputText}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
        sx={styles.outlinedInput}
        InputLabelProps={{
          style: {
            right: 30,
            textAlign: "right",
            transformOrigin: "top right",
          },
        }}
        dir="rtl"
        inputProps={{
          type: 'number',
          pattern: '[0-9]*'
        }}
      />
      <Button
      style={{borderRadius: '32px', height: "40px", opacity: !voterData ? 0.6 : 1}} 
      fullWidth
      variant="contained"
      color="primary"
      onClick={handleVote}
      //disabled={!voterData }
      //className={`${(!voterData) ? classes.disabledButton : ''}`}
      >
        הצביע
      </Button>
      <br></br>
      {voterData && inputText && (
        <div id="voterDataDiv" style={{ color: "black", boxSizing: "content-box", border: "solid",  marginBottom: '30px'  }}>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            פרטי הבוחר
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            ת.ז: {voterData.id}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם פרטי: {voterData.first_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם האב: {voterData.father_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם משפחה: {voterData.last_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר קלפי: {voterData.ballot[0]}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            בית ספר: {voterData.school}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר סידורי: {voterData.ballot_order_number}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            הצביע: {voterData.voted ? 'כן' : 'לא'}
          </Typography>
          {voterData.voted && (
            <Typography variant="h4" style={{ textAlign: "right" }}>
              שעת הצבעה: {voterData.voted_at}
            </Typography>
          )}
        </div>
      )}
  
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
  
      <div ref={fixedDivRef} style={{ backgroundColor: bottomBackgroundColor, display: 'flex', justifyContent: 'space-between', padding: '10px', position: 'fixed', bottom: '10px', left: '10px', right: '10px' }}>
        <div>
          <Button
            component="label"
            onClick={handleRevert}
            variant="contained"
            tabIndex={-1}
            color='warning'
            style={{ fontSize: '14px', fontWeight: "bold" }}
            startIcon={<ReplayIcon />}
          >
            שחזור
          </Button>
        </div>
        <div>
          <Typography variant="subtitle1" style={{ color: 'black', fontSize: "24px" }}>
            הצביעו לאחרונה (5):
            <IconButton onClick={handlePopoverOpen}>
              <ExpandLessIcon fontSize="large" style={{ color: 'black' }} />
            </IconButton>
          </Typography>
        </div>
      </div>
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '400px', // Set the desired width
            maxHeight: '500px', // Set the desired height
          },
        }}
      >
        <div style={{ padding: '10px', borderRadius: '25px' }}>
          {lastVotedIds.map((voterInfo, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Typography variant="subtitle2" style={{ fontSize: "16px", flex: 1, }}>
                ({index + 1}) {voterInfo.id} - {voterInfo.first_name} {voterInfo.last_name}
              </Typography>
              <Button
                onClick={() => handleRevertWithId(voterInfo.id)} // Pass the voter's ID to handleRevert
                variant="contained"
                color="warning"
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                שחזור
              </Button>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
  };
  
  export default BallotVote;