import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import baseURL from "../../axiosConfig"; // Import the baseURL

//axios.defaults.baseURL = 'https://elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;

const PersonVoted = () => {
  const { ballot } = useParams();

  const [searchId, setSearchId] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [voterData, setVoterData] = useState(null);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [isLastTwoCheckboxesSelected, setIsLastTwoCheckboxesSelected] = useState(false);

  const getIdFromSelection = (selectedLetter, type) => {
    // Check conditions and return the corresponding id
    if (selectedLetter === 'S' && type === 1) {
      return "4c99accb-4c0c-4afb-9158-d8332d2d876f";
    } 
    else if (selectedLetter === 'K' && type === 1) {
      return "fafa24c5-7f8d-49e5-8572-f21a753b25f3";
    } 
    // else if (selectedLetter === 'A' && type === 1) {
    //   return "2e52ab68-4001-4205-b45e-394035d1e461";
    // } else if (selectedLetter === 'E' && type === 1) {
    //   return "ab01e288-00c9-4002-a99a-d26778c51d2e";
    // } 
    // else if (selectedLetter === 'S' && type === 2) {
    //   return "04f4d1b0-c76d-45d3-8d35-c561cfb8b3a1";
    // } else if (selectedLetter === 'K' && type === 2) {
    //   return "58d43a4b-b73c-4bc2-bb13-3917da8b2c64";
    // } 
    // else if (selectedLetter === 'A' && type === 2) {
    //   return "2913b051-f6ca-40a6-ace1-4d0328579394";
    // } else if (selectedLetter === 'E' && type === 2) {
    //   return "2b8dbe9a-f5f5-4313-81e9-93805bde11ed";
    // }else if (selectedLetter === 'N' && type === 2) {
    //   return "f7a31dff-025a-47fb-8d4e-a6be953097b8";
    // } else if (selectedLetter === 'H' && type === 2) {
    //   return "50147ff6-d455-452c-8ef3-7cb52810bdf3";
    // }

    return 0;
  };

  const handleCheckboxChange = (letter) => {
    setSelectedLetter(letter);
    setIsCheckboxSelected(true);

    // Check if the last two checkboxes are selected
    // setIsLastTwoCheckboxesSelected(letter === 'N' || letter === 'H');
  };

  const handleVote = async (type) => {
    const id = getIdFromSelection(selectedLetter, type);
    const ballotString = (ballot / 10).toString()
    try {
      await axios.put(`/elections/votes`, {
        ballot_number: ballotString,
        id: id.toString(),
      });

      notify();
    } catch (error) {
      console.error('Error updating data:', error.message);
    } finally {
      // Reset selectedLetter and setIsCheckboxSelected after the button is pressed
      setSelectedLetter("");
      setIsCheckboxSelected(false);
    }
  };

  const getBallotDisplay = (ballot) => {
    const ballotNumber = parseInt(ballot);
    if (!isNaN(ballotNumber) && ballotNumber % 10 === 0 && ballotNumber >= 10 && ballotNumber <= 90) {
      const displayNumber = ballotNumber / 10;
      return `קלפי ${displayNumber}`;
    }
    return "Unknown Ballot";
  };

  const notify = () => toast("🐙🐙🐙 הצביע");
  
  return (
    <div style={{
      backgroundColor: "#fff0db", minHeight: "100vh",
      display: "flex", flexDirection: "column",
    }}>
      <div style={{ textAlign: 'right' }} >
        <h1 style={{ color: "black", textAlign: "center" }}>قائمه ي.ن برئاسه الحاج سليم</h1>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="100px"
          height="100px"
          src={`../../assets/elections-logo.jpg`}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <h1 style={{ color: "black", textAlign: "center" }}>{getBallotDisplay(ballot)}</h1>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('S')}
            checked={selectedLetter === 'S'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه ي.ن برئاسه الحاج سليم
          </Typography>
        }
        labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('K')}
            checked={selectedLetter === 'K'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه أ برئاسه كاظم ابراهيم
          </Typography>
        }
        labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('A')}
            checked={selectedLetter === 'A'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه ت.م برئاسه علاء جبر
          </Typography>
        } labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      /> */}
      {/* <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('E')}
            checked={selectedLetter === 'E'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه ع.ج برئاسه احسان عبدالرحمان
          </Typography>
        }
        labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      /> */}

      {/* <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('N')}
            checked={selectedLetter === 'N'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه ش عضويه احمد عبدالرحمان
          </Typography>
        } labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      /> */}

      {/* <FormControlLabel
        control={
          <Checkbox
            onChange={() => handleCheckboxChange('H')}
            checked={selectedLetter === 'H'}
            sx={{ color: 'black' }}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
            قائمه ع عضويه حسن عثمان
          </Typography>
        } labelPlacement="start" // Set labelPlacement to "start" to move the label to the left
        sx={{ marginLeft: 'auto', marginRight: '10px', textAlign: 'right', color: 'black' }}
      /> */}

<Box display="flex" marginTop="20px">
        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%' }}
          onClick={() => handleVote(1)}
          disabled={!isCheckboxSelected || isLastTwoCheckboxesSelected}
        >
          رئاسه
        </Button>
        {/* <Button
          variant="contained"
          color="warning"
          style={{ width: '50%' }}
          onClick={() => handleVote(2)}
          disabled={!isCheckboxSelected}
        >
          عضويه
        </Button> */}
      </Box>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default PersonVoted;
