import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Invoices from "./scenes/invoices";
import Vote from "./scenes/vote"
import FamilyStats from "./scenes/FamilyStats";
import BallotStats from "./scenes/BallotStats";
import Calendar from "./scenes/calendar/calendar";
import PasswordDialog from "./scenes/password/PasswordDialog"; // Adjust the path accordingly
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import BallotVote from "./scenes/ballotVote";
import Details from "./scenes/details"
import PersonVoted from "./scenes/personVoted";
import PersonVoted2 from "./scenes/personVoted2";
import ResultStats from "./scenes/ResultStats";
import ResultStats2 from "./scenes/ResultStats2";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const location = useLocation();
  const navigate = useNavigate(); // useNavigate replaces useHistory in React Router v6
const isVoteRoute = /^\/(idvote|bltnewthrday2\/\d+|detailsnow|personvotingnow\/\d+|newcallok2\/\d+)$/.test(location.pathname);

  useEffect(() => {
    // Check if the current route is /admin and the password hasn't been entered
    if (location.pathname === "/kosemememak98" && !passwordEntered) {
      setShowPasswordDialog(true);
    }
  }, [location.pathname, passwordEntered]);

  const handlePasswordSubmit = (password) => {
    if (password === "kosemememak98") {
      setIsSidebar(true);
      setPasswordEntered(true);
      // Navigate to /admin after successful password entry
      navigate("/kosemememak98");
    }
    setShowPasswordDialog(false);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isSidebar && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
          {!isVoteRoute && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              {/* <Route path="/main" element={<Invoices />} /> */}
              <Route path="/detailsnow" element={<Details />} />
              {/* <Route path="/idvote" element={<Vote />} /> */}
              {/* <Route path="/bltnewthrday2/:ballot" element={<BallotVote />} /> */}
              {/* <Route path="/personvotingnow/:ballot" element={<PersonVoted />} /> */}
              {/* <Route path="/newcallok2/:ballot" element={<PersonVoted2 />} /> */}
              {/* <Route path="/invoices/:ballot" element={<Invoices />} /> */}
              {/* <Route path="/mainpagestuff" element={<Invoices />} /> */}
              {/* <Route path="/family-stats" element={<FamilyStats />} /> */}
                  {/* <Route path="/ballot-stats" element={<BallotStats />} /> */}
                  {/* <Route path="/result-stats" element={<ResultStats />} /> */}
                  {/* <Route path="/result-stats2" element={<ResultStats2 />} /> */}
              {isSidebar && (
                <>


                  {/* <Route path="/calendar" element={<Calendar />} /> */}
                </>
              )}
            </Routes>
          </main>
        </div>
        {showPasswordDialog && (
          <PasswordDialog
            open={showPasswordDialog}
            onClose={() => setShowPasswordDialog(false)}
            onPasswordSubmit={handlePasswordSubmit}
          />
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
