import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/Header";
import baseURL from "../../axiosConfig"; // Import the baseURL

//axios.defaults.baseURL = 'https:c6e3-2a10-8012-17-ac06-e464-98eb-2a1f-//elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;



// Use the ColorModeContext to set the default mode for this component


const Vote = () => {
  const [searchId, setSearchId] = useState("");
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [voterData, setVoterData] = useState(null);

  const styles = {
    outlinedInput: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black', // Set the border color when not active,
        textAlign: "right"

      },
      '& .MuiInputBase-input': {
        color: 'black', // Set the text color,
        textAlign: "right"
      },
      '& .MuiInputLabel-root': {
        color: 'black', // Set the label color,
        textAlign: "right"

      },
    },
  };


  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setSearchId(inputText);
    
    // Check if the inputText is not empty before making the API request
    if (inputText.trim() !== "") {
      axios.get(`/elections/data`, {
        params: {
          id: inputText,
          page: 1
        }
      })
      .then((response) => {
        const voterInfo = response.data.results[0];
        setVoterData(voterInfo);
      })
      .catch((error) => {
        console.error('Error fetching data:', error.message);
      });
    }
  };
  

  const handleVote = async () => {
    try {
      await axios.put(`/elections/data`, {
        id: searchId,
        voted: true,
      });

      notify();
    } catch (error) {
      console.error('Error updating data:', error.message);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessToastOpen(false);
  };

  const notify = () => toast("🐙🐙🐙 הצביע");

  return (
    <div style={{
      backgroundColor: "#fff0db", minHeight: "100vh",
      display: "flex", flexDirection: "column",
    }}>
      <div style={{ textAlign: 'right' }} >
        <h1 style={{ color: "black", textAlign: "center" }}>قائمه ي.ن برئاسه الحاج سليم</h1>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/elections-logo.jpg`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
      <TextField
        label="ת.ז"
        style={{ direction: "rtl" }}

        variant="outlined"
        value={searchId}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
        sx={styles.outlinedInput}
        
        InputLabelProps={{
          style: {
            right: 30,  // Adjust this value as needed
            textAlign: "right",
            transformOrigin: "top right",
          },
          
        }}
        dir="rtl"
        inputProps={{
          type: 'number',
          pattern: '[0-9]*'
        }}
      />
      <Button fullWidth variant="contained" color="primary" onClick={handleVote}>
        הצביע
      </Button>

      <br></br>
      {voterData && (
        <div style={{color: "black", boxSizing: "content-box", border: "solid"}}>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            פרטי הבוחר
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            ת.ז: {voterData.id}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם פרטי: {voterData.first_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם האב: {voterData.father_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם משפחה: {voterData.last_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר קלפי: {voterData.ballot[0]}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            בית ספר: {voterData.school}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר סידורי: {voterData.ballot_order_number}
          </Typography>
        </div>
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Vote;
