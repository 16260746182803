import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const optionsPerBallot = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'ספירת קולות',
    },
  },
};

const labelsPerBallot = [
  '1 קלפי',
  '2 קלפי',
  '3 קלפי',
  '4 קלפי',
  '5 קלפי',
  '6 קלפי',
  '7 קלפי',
  '8 קלפי',
  '9 קלפי',
];

export const dataPerBallot = {
  labels: labelsPerBallot,
  datasets: [
    {
      label: 'قائمه ي.ن برئاسه الحاج سليم',
      data: [],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'قائمه أ برئاسه كاظم ابراهيم',
      data: [],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export const optionsTotal = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'left',
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

const labelsTotal = ['رئاسه'];

export const dataTotal = {
  labels: labelsTotal,
  datasets: [
    {
      label: 'قائمه ي.ن برئاسه الحاج سليم',
      data: [],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      label: 'قائمه أ برئاسه كاظم ابراهيم',
      data: [],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};
