import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import baseURL from "../axiosConfig";
import { optionsPerBallot, optionsTotal, dataPerBallot, dataTotal } from './ChartConfig'; // Assuming you have the chart configuration in a separate file

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const mockData = [
  { ballot: 1, name: 'S', total_votes: 100 },
  { ballot: 1, name: 'K', total_votes: 20 },
  { ballot: 2, name: 'S', total_votes: 200 },
  { ballot: 2, name: 'K', total_votes: 300 },
  { ballot: 3, name: 'S', total_votes: 300 },
  { ballot: 3, name: 'K', total_votes: 145 },
  { ballot: 4, name: 'S', total_votes: 190 },
  { ballot: 4, name: 'K', total_votes: 20 },
  { ballot: 5, name: 'S', total_votes: 211 },
  { ballot: 5, name: 'K', total_votes: 168 },
  { ballot: 6, name: 'S', total_votes: 200 },
  { ballot: 6, name: 'K', total_votes: 145 },
  { ballot: 7, name: 'S', total_votes: 187 },
  { ballot: 7, name: 'K', total_votes: 54 },
  { ballot: 8, name: 'S', total_votes: 68 },
  { ballot: 8, name: 'K', total_votes: 12 },
  { ballot: 9, name: 'S', total_votes: 200 },
  { ballot: 9, name: 'K', total_votes: 145 },
  // Add more data as needed
];

const getVotesPerBallot = (data, candidateName) => {
  const votes = data
    .filter(item => item.name === candidateName)
    .map(item => item.total_votes);
  return votes;
};




const getVotesPerCandidate = (apiData, candidateName) => {
  const ballots = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const votesPerCandidate = apiData
    .filter(item => Object.values(item).some(subItem => subItem.name === candidateName))
    .map(item => {
      const totalVotesForCandidate = ballots.reduce((total, ballotNumber) => {
        const matchingItem = item[ballotNumber];
        return total + (matchingItem ? (matchingItem.total_votes || 0) : 0);
      }, 0);

      return totalVotesForCandidate;
    });

  return votesPerCandidate;
};


const getTotalVotes = (data) => {
  return data.reduce((total, votes) => total + votes, 0);
};

const BarGraph = () => {

  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseURL}/elections/parties/results`
        );
        const data = await response.json();

        setApiData(data);
      } catch (error) {
        console.error('Error fetching data from API:', error);
      }
    };

    fetchData();
  }, []);


  const votesPerBallotForS = getVotesPerCandidate(apiData, 'قائمه ي.ن برئاسه الحاج سليم');
  const votesPerBallotForK = getVotesPerCandidate(apiData, 'قائمه أ برئاسه كاظم ابراهيم');

  const votesTotalForS = getTotalVotes(votesPerBallotForS);
  const votesTotalForK = getTotalVotes(votesPerBallotForK);

  
  return (
    <div>
      <h2 style={{textAlign: 'right'}}>ספירת קולות</h2>
      <Bar options={optionsPerBallot} data={{ ...dataPerBallot, datasets: [{ ...dataPerBallot.datasets[0], data: votesPerBallotForS }, { ...dataPerBallot.datasets[1], data: votesPerBallotForK }] }} />
      <Bar options={optionsTotal} data={{ ...dataTotal, datasets: [{ ...dataTotal.datasets[0], data: [votesTotalForS] }, { ...dataTotal.datasets[1], data: [votesTotalForK] }] }} />;
    </div>
  );
};

export default BarGraph;
