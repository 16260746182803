import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography, FormControlLabel } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import baseURL from "../../axiosConfig"; // Import the baseURL
import salimImage from "../../components/Salim_bg.png"
import kademImage from "../../components/Kadem_bg.png"
// import trashImage from "../../components/trash.jpg"
import EmojiReaction from "../../components/EmojiReaction";
//axios.defaults.baseURL = 'https://elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;

const getVotesForBallot = (apiData, candidateName, ballot) => {
  let ballotString = ballot / 10;
  const votesForCandidate = apiData
    .filter(item => Object.values(item).some(subItem => subItem.name === candidateName))
    .map(item => {
      const matchingItem = item[ballotString];
      const totalVotesForCandidate = matchingItem ? (matchingItem.total_votes || 0) : 0;
      return totalVotesForCandidate;
    });

  return votesForCandidate[ballotString - 1];
};

const imageBorderStyle = {
  cursor: "pointer",
  border: "2px solid black",
  borderRadius: "5px", // Adjust the border-radius for rounded corners
};

const PersonVoted2 = () => {
  const { ballot } = useParams();

  const [countS, setCountS] = useState(0);
  const [countK, setCountK] = useState(0);
  // const [countTrash, setCountTrash] = useState(0);
  // const handleUncountedClick = () => {
  //   setCountTrash(countTrash + 1);
  // };
  const [apiData, setApiData] = useState([]);
  const [votesPerBallotForS, setVotesPerBallotForS] = useState(getVotesForBallot(apiData, 'قائمه ي.ن برئاسه الحاج سليم'));
  const [votesPerBallotForK, setVotesPerBallotForK] = useState(getVotesForBallot(apiData, 'قائمه أ برئاسه كاظم ابراهيم'));

  const mainBackgroundColor = "#f1f1f4";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseURL}/elections/parties/results`
        );
        const data = await response.json();

        setApiData(data);
        setVotesPerBallotForS(getVotesForBallot(data, 'قائمه ي.ن برئاسه الحاج سليم', ballot));
        setVotesPerBallotForK(getVotesForBallot(data, 'قائمه أ برئاسه كاظم ابراهيم', ballot));

      } catch (error) {
        console.error('Error fetching data from API:', error);
      }
    };

    fetchData();
  }, []);



  const [isFlyingS, setIsFlyingS] = useState(false);
  const [isFlyingK, setIsFlyingK] = useState(false);


  const flyEmojiS = () => {
    setIsFlyingS(true);

    // Reset position after animation completes
    setTimeout(() => {
      setIsFlyingS(false);
    }, 1000);
  };

  const flyEmojiK = () => {
    setIsFlyingK(true);

    // Reset position after animation completes
    setTimeout(() => {
      setIsFlyingK(false);
    }, 1000);
  };


  const handleVote = async (letter) => {
    const id = (letter === 'S' ? "4c99accb-4c0c-4afb-9158-d8332d2d876f" : "fafa24c5-7f8d-49e5-8572-f21a753b25f3");
    const ballotString = (ballot / 10).toString();
  
    try {
      toast.dismiss();
  
      const response = await axios.put(`/elections/v2_voting`, {
        ballot_number: ballotString,
        id: id.toString(),
      });
  
      // Check if the vote was successful based on the HTTP status code
      if (response.status === 200) {
        letter === 'S' ? notifyS() : notifyK();
        letter === 'S' ? flyEmojiS() : flyEmojiK();
        
        // Update the counter only if the vote is successful
        if (letter === 'S') {
          setCountS(countS + 1);
          setVotesPerBallotForS(votesPerBallotForS + 1);
        } else if (letter === 'K') {
          setCountK(countK + 1);
          setVotesPerBallotForK(votesPerBallotForK + 1);
        }
      } else {
        // Handle unsuccessful vote
        console.error('Vote was not successful.');
      }
    } catch (error) {
      notifyError();
      console.error('Error updating data:', error.message);
    }
  };
  
  

  const getBallotDisplay = (ballot) => {
    const ballotNumber = parseInt(ballot);
    if (!isNaN(ballotNumber) && ballotNumber % 10 === 0 && ballotNumber >= 10 && ballotNumber <= 90) {
      const displayNumber = ballotNumber / 10;
      return `קלפי ${displayNumber}`;
    }
    return "Unknown Ballot";
  };

  const notifyS = () => toast("🐙🐙🐙");
  const notifyK = () => toast("🤦‍♂️🤦‍♂️🤦‍♂️");
  const notifyError = () => toast("תקלה טכנית... 🤷‍♂️");


  return (
    <div style={{ backgroundColor: mainBackgroundColor, minHeight: "100vh", display: "flex", flexDirection: "column" }}>

      <div style={{ textAlign: 'right' }}>
        <h1 style={{ color: "black", textAlign: "center" }}>قائمه ي.ن برئاسه الحاج سليم</h1>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="100px"
          height="100px"
          src={`../../assets/elections-logo.jpg`}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <h1 style={{ color: "black", textAlign: "center" }}>{getBallotDisplay(ballot)}</h1>
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
      <div style={{ ...imageBorderStyle, width: "50%" }} onClick={() => handleVote('K')}>
          <img
            alt="option-K"
            width="100%"
            height="200px"
            src={kademImage}
            style={{ borderRadius: "inherit" }}
          />
        </div>
        <div style={{ ...imageBorderStyle, width: "50%" }} onClick={() => handleVote('S')}>
          <img
            alt="option-S"
            width="100%"
            height="200px"
            src={salimImage}
            style={{ borderRadius: "inherit" }}
          />
        </div>

      </div>
      <div style={{
        position: 'relative', display: 'flex', justifyContent: 'space-between', marginTop: '10px',
        marginLeft: '10px', marginRight: '10px'
      }}>

        <Typography variant="body1" sx={{ fontSize: '19px', color: 'black', textAlign: 'center' }}>
          {`قائمه أ برئاسه كاظم ابراهيم `}
          <br></br>
          <strong style={{ fontWeight: 'bold' }}>{votesPerBallotForK}</strong>
          <div className={`flying-emoji ${isFlyingK ? 'flying' : ''}`}>🤦‍♂️🤦‍♂️🤦‍♂️🤦‍♂️</div>
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '19px', color: 'black', textAlign: 'center' }}>
          {`قائمه ي.ن برئاسه الحاج سليم `}
          <br></br>
          <strong style={{ fontWeight: 'bold' }}>{votesPerBallotForS}</strong>
          <div className={`flying-emoji ${isFlyingS ? 'flying' : ''}`}>🐙🐙🐙🐙</div>
        </Typography>

      </div>
      {/* <div style={{ width: "50%", textAlign: "center", alignSelf: "center" }}>
          <img
            alt="option-Uncounted"
            width="100%"
            height="200px"
            src={trashImage}
            style={{ cursor: "pointer", border: "1px solid black" }}
            onClick={handleUncountedClick}
          />
        </div> */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default PersonVoted2;
