import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../components/Header";
import baseURL from "../../axiosConfig"; // Import the baseURL

//axios.defaults.baseURL = 'https://elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;

const Details = () => {
  const [searchId, setSearchId] = useState("");
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [voterData, setVoterData] = useState(null);

  const mainBackgroundColor = "#f1f1f4";

  // const useStyles = makeStyles({
  //   outlinedInput: {
  //     borderRadius: '32px !important', // Use !important to override default styles
  //   },
  // });

  const styles = {
    outlinedInput: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
        textAlign: "right"
      },
      '& .MuiInputBase-input': {
        color: 'black',
        textAlign: "right"
      },
      '& .MuiInputLabel-root': {
        color: 'black',
        textAlign: "right"
      },
      // '& .MuiInputBase-root': {
      //   borderRadius: '32px',
      // },
      '& .MuiOutlinedInput-root': {
        borderRadius: '32px',
      },
    },
  };

  const handleSearchChange = (event) => {
    setSearchId(event.target.value);
  };

  const handleVote = async () => {
    try {
      const response = await axios.get(`/get_data/all`, {
        params: {
          id: searchId,
          page: 1
        }
      });

      const voterInfo = response.data.results[0];
      setVoterData(voterInfo);
      if(!voterInfo) {
        notifyUserNotFound();
        setVoterData(null);
      }

    } catch (error) {
      notifyUserNotFound();
      console.error('Error reverting vote:', error.message);
    }
  };
  
  const notifyUserNotFound = () => toast.error("❌ !!!תעודת זהות לא נמצאת");

  return (
    <div style={{
      backgroundColor: mainBackgroundColor, minHeight: "100vh",
      display: "flex", flexDirection: "column", padding: '10px'
    }}>
      <div style={{ textAlign: 'right' }} >
        <h1 style={{ color: "black", textAlign: "center" }}>قائمه ي.ن برئاسه الحاج سليم</h1>
      </div>

      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="100px"
          height="100px"
          src={`../../assets/elections-logo.jpg`}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <TextField
        label="ת.ז"
        style={{ direction: "rtl", borderRadius: 32 }}
        variant="outlined"
        value={searchId}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
        sx={styles.outlinedInput}
        InputLabelProps={{
          style: {
            right: 30,
            textAlign: "right",
            transformOrigin: "top right",
          },
        }}
        dir="rtl"
        inputProps={{
          type: 'number',
          pattern: '[0-9]*'
        }}
      // className={classes.outlinedInput}
      />
      <Button
        style={{ borderRadius: '32px', height: "40px" }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleVote}
        >
        מצא
      </Button>
      <br></br>
      {voterData && (
        <div style={{ color: "black", boxSizing: "content-box", border: "solid", borderRadius: "10%",  padding: '10px' }}>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            פרטי הבוחר
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            ת.ז: {voterData.id}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם פרטי: {voterData.first_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם האב: {voterData.father_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            שם משפחה: {voterData.last_name}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר קלפי: {voterData.ballot[0]}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            בית ספר: {voterData.school}
          </Typography>
          <Typography variant="h4" style={{ textAlign: "right" }}>
            מספר סידורי: {voterData.ballot_order_number}
          </Typography>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Details;
