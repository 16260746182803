import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Typography } from "@mui/material";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useParams, useLocation } from "react-router-dom";

import MaterialTable from 'material-table'
import '@mui/material/styles';
import '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import { FirstPage, LastPage, ChevronLeft, ChevronRight, Search, Clear } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Checkbox from '@mui/material/Checkbox';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import baseURL from "../../axiosConfig"; // Import the baseURL

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

//axios.defaults.baseURL = 'https://elections-bice.vercel.app/v1';
axios.defaults.baseURL = baseURL;

const Invoices = () => {
  const location = useLocation();
  const { ballot } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableKey, setTableKey] = useState(0);
  const [users, setUsers] = useState([]);
  const [checkboxStatus, setCheckboxStatus] = useState({});

  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [successToastOpen, setSuccessToastOpen] = useState(false);


  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmChange = () => {
    handleApply(selectedUser);
    handleCloseDialog();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch users data
        const response = await axios.get('/elections/data');
        const usersData = response.data.results;

        // Set the users and initialize checkbox status based on 'voted' parameter
        setUsers(usersData);

        const initialCheckboxStatus = {};
        usersData.forEach((user) => {
          initialCheckboxStatus[user.id] = user.voted;
        });
        setCheckboxStatus(initialCheckboxStatus);
        setInitialDataFetched(true);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    // Call the fetchData function only if initial data has not been fetched
    if (!initialDataFetched) {
      fetchData();
    }
  }, [initialDataFetched]);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.default, // Set the background color to the default background color
      color: theme.palette.common.white,
      textAlign: 'right', // Align text to the right in body cells
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: 'right', // Align text to the right in body cells

    },
  }));

  const StyledVotedTableCell = styled(TableCell)(({ theme, voted }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: 'right', // Align text to the right in body cells
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: voted ? "#90EE90" : "#FF7F7F",
      textAlign: 'right',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleCheckboxChange = (rowData) => {
    setCheckboxStatus((prevStatus) => ({
      ...prevStatus,
      [rowData.id]: !rowData.voted,
    }));
  };


  const handleApply = async (rowData) => {
    try {
      const newVotedStatus = checkboxStatus[rowData.id];

      await axios.put(`/elections/data`, {
        id: rowData.id,
        voted: !rowData.voted,
      });

      // Update state without fetching data again
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === rowData.id ? { ...user, voted: !rowData.voted } : user
        )
      );

      // Force re-render by changing the key
      setTableKey((prevKey) => prevKey + 1);

      // Display success alert
      setSuccessToastOpen(true);

    } catch (error) {
      console.error('Error updating data:', error.message);
    }
  };


  const columns = [
//     {
//       title: "לשנות",
//       field: "edit",
//       render: (rowData) => (
//         <>
//   <Box display="flex" alignItems="center">
//     <Button
//       onClick={() => handleApply(rowData, rowData.checkboxStatus)}
//       sx={{
//         backgroundColor: colors.blueAccent[700],
//         color: colors.grey[100],
//         fontSize: "14px",
//         fontWeight: "bold",
//         padding: "10px 20px",
//         marginRight: "10px", // Add margin to create space between Button and Checkbox
//       }}
//     >
//       <HowToVoteIcon sx={{ mr: "10px" }} />
//       לשנות
//     </Button>
//     <Checkbox
//       checked={checkboxStatus[rowData.id]}
//       onChange={() => handleCheckboxChange(rowData)}
//     />
//   </Box>
// </>
//       ),
//       cellStyle: { textAlign: 'right' },
//       headerStyle: {
//         textAlign: 'right', className: 'custom-header',
//         backgroundColor: colors.blueAccent[700],
//         borderStyle: "solid", borderColor: "white",
//         borderTopColor: colors.blueAccent[700],
//         borderBottomColor: colors.blueAccent[700],
//         padding: "10px 10px 10px 10px",
//         fontSize: "20px",
//         cursor: "pointer"
//       }
//     },
    {
      title: "הצביע", field: "voted",
      cellStyle: { textAlign: 'right', className: 'custom-cell' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "משפחה", field: "secondary_family_name",
      cellStyle: { textAlign: 'right', className: 'custom-cell' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "מס קלפי", field: "ballot",
      cellStyle: { textAlign: 'right', className: 'custom-cell' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "בית ספר", field: "school",
      cellStyle: { textAlign: 'right', className: 'custom-cell' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "מס בית", field: "house_number",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "רחוב", field: "street",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "שם אב", field: "father_name",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "שם משפחה", field: "last_name",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "שם פרטי", field: "first_name",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "ת.ז", field: "id",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    },
    {
      title: "מס' סידורי", field: "ballot_order_number",
      cellStyle: { textAlign: 'right' },
      headerStyle: {
        textAlign: 'right', className: 'custom-header',
        backgroundColor: colors.blueAccent[700],
        borderStyle: "solid", borderColor: "white",
        borderTopColor: colors.blueAccent[700],
        borderBottomColor: colors.blueAccent[700],
        padding: "10px 10px 10px 10px",
        fontSize: "20px",
        cursor: "pointer"
      }
    }
  ]; //cursor pointer


  return (
    <Box m="20px">
      <div style={{textAlign: 'right'}}>
      <Header title="בחירות לרשויות המקומיות" subtitle="רשימת המצביעים"/>
      </div>
      {<Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.mode == 'light' ? '#f2f0f0' : '', // Change background color to red
          },
          "& .MuiDataGrid-root": {
            border: "none",
            backgroundColor: theme.palette.background.default, // Set the background color for the entire table
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle> 
          <Typography variant="h6" style={{fontSize: "20px"}}>
            <span style={{ fontWeight: 'bold' }}>
            ?{`${selectedUser?.first_name} ${selectedUser?.last_name}`}
            </span>{" "}
            {selectedUser?.voted === false
            ? 'הצביע'
            : 'לא הצביע'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* Add content here if needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary"
          sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    marginRight: "10px", // Add margin to create space between Button and Checkbox
                  }}>
            ביטול
          </Button>
          <Button onClick={handleConfirmChange} color="warning"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "10px", // Add margin to create space between Button and Checkbox
          }}>
            אישור
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successToastOpen}
        autoHideDuration={2000} // Adjust the duration as needed
        onClose={() => setSuccessToastOpen(false)}
      >
        <Alert severity="success" sx={{ backgroundColor: 'green' }}>
          {selectedUser?.voted === false
            ? `${selectedUser?.first_name} ${selectedUser?.last_name} הצביע`
            : `${selectedUser?.first_name} ${selectedUser?.last_name} לא הצביע`}
        </Alert>
      </Snackbar>
        <MaterialTable
          key={tableKey}
          className="custom-material-table"
          title="רשימת המצביעים"
          columns={columns.map((col) => ({
            ...col,
            render: (rowData) => {
              if (col.field === "edit") {
                return col.render(rowData);
              } else if (col.field === "voted") {
                return (
                  <StyledTableRow>
                  <StyledVotedTableCell
                    align="right"
                    voted={rowData.voted}
                    onClick={() => handleOpenDialog(rowData)} // Add onClick event
                  >
                    {rowData.voted ?
                      <ThumbUpIcon sx={{ mr: "10px", cursor: "pointer" }} /> :
                      <ThumbDownIcon sx={{ mr: "10px", cursor: "pointer" }} />}
                  </StyledVotedTableCell>
                </StyledTableRow>
                );
              } else {
                return (
                  <StyledTableRow>
                    <StyledTableCell align="right">
                      {rowData[col.field]}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
            },
          }))}
          options={{
            debounceInterval: 700,
            padding: "dense",
            filtering: true,
            pageSize: 25,
            pageSizeOptions: [],
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              //let url = 'https://elections-bice.vercel.app/v1/elections/data?';
              let url = `${baseURL}/elections/data?`;
              
              if (ballot) {
                url+=`ballot=${ballot}&`
              }
              if (query.search) {
                url += `street_number=${query.search}`;
              }
              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  return `${filter.column.field}${filter.operator}${filter.value}&`;
                });
                url += `${filter.join('')}`;
              }
              url += `page=${query.page + 1}`
              if (query.orderBy) {
                url += `&sort=${query.orderBy.field}:${query.orderDirection}`;
              };

              fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                  resolve({
                    data: resp.results,
                    page: query.page,
                    totalCount: 5854,
                  });
                });
            })
          }
          icons={{
            FirstPage: FirstPage,
            LastPage: LastPage,
            NextPage: ChevronRight,
            PreviousPage: ChevronLeft,
            Filter: FilterListIcon,
            SortArrow: ArrowDownwardIcon,
            Search: Search,
            Clear: Clear
          }}
        />
      </Box>}
      <>
        <ThemeProvider theme={theme}>

        </ThemeProvider>
        {/* ... existing commented out components */}
      </>
    </Box>


  );
};

export default Invoices;
